.Container {
    height: 100vh;

    overflow: hidden;

}

.ImgContainer {
    background-blend-mode: overlay !important;
    background-repeat    : no-repeat !important;
    background-size      : cover !important;
    background-color     : rgba(0, 0, 0, 0.5) !important;

    width : 116.7%;
    height: 116.7%;

    position: relative;
    top     : 0;
    left    : -12%;
}

.LogoContainer {
    height: 100%;
    width : 100%;

    position: absolute;
    top     : 0;
    left    : 0;

    display        : flex;
    justify-content: center;
    align-items    : center;
}

.ImgLogo {
    width: 20%;
}

.Content {
    position: absolute;
    bottom  : 0;
    left    : 0;

    width  : 100%;
    padding: 0 80px 80px;

    display        : flex;
    justify-content: space-between;
    align-items    : center;
}

.Content section {
    margin-bottom: 40px;
}

.Content section>* {
    margin-right: 40px;
}

.Content section>*:last-child {
    margin-right: 0;
}