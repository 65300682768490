.Panel {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    gap            : 60px;

    height : 100vh;
    padding: 2rem;

    color     : #FFF;
    font-size : 17px;
    text-align: center;

}

.Panel>div {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    gap            : 60px;
}

.Button {
    border    : none;
    background: transparent;

    cursor: pointer;
}


.Img {
    width : 200px;
    height: auto;
}