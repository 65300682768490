.App,
body,
html,
#root {
    height: 100%;
    background-color: var(--09-vucos-grey);
    caret-color: var(--main-color);
}

::-webkit-scrollbar {
    height: 3px;
    width: 3px;
    /* background: red; */
}

/* Track */
::-webkit-scrollbar-track {
    background: #16161D;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

::-webkit-scrollbar-thumb:horizontal {
    background: #888;
    border-radius: 10px;

    cursor: pointer;
}

:focus-visible {
    outline: none;
}

.toast {
    font-size: var(--font-19);
    line-height: 120%;
    /* max-width  : 650px; */
    padding: 20px 20px;

    max-width: unset !important;
}

.toast-text {
    font-size: var(--font-20);
    margin-left: 20px;

    white-space: nowrap;
}

button:hover,
a:hover {
    cursor: pointer;
}


.empty {
    font-weight: 400;
    font-size: var(--font-24);
    line-height: 120%;
    color: var(--04-vucos-grey);
}

.blue-dot {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-left: 15px;
    border-radius: 50%;
    background-color: var(--main-color);
}
